/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
       url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
       url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Merriweather/merriweather-v21-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-sans-regular - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-sans-italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Merriweather/merriweather-sans-v13-latin-italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Thin.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedThin.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedThin.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ThinOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ThinOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ThinOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ThinOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedThinOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedThinOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedThinOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedThinOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ThinItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedThinItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraLight.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraLight.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraLightItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraLightItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Light.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedLight.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-LightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-LightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-LightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-LightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedLightOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedLightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-LightItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedLightItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Regular.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Extended.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Extended.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Oblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Oblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Oblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Oblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Italic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Medium.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedMedium.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedMedium.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-MediumOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-MediumOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-MediumOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-MediumOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedMediumOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedMediumOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedMediumOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedMediumOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-MediumItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedMediumItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedMediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-SemiBold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedSemiBold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedSemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-SemiBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-SemiBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-SemiBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-SemiBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedSemiBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedSemiBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedSemiBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedSemiBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-SemiBoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedSemiBoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedSemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Bold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedBold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-BoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-BoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-BoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-BoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-BoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedBoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraBold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraBold.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraBoldOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraBoldOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtraBoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedExtraBoldItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedExtraBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-Heavy.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-Heavy.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedHeavy.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedHeavy.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-HeavyOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-HeavyOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-HeavyOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-HeavyOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: oblique;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedHeavyOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedHeavyOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web Oblique';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedHeavyOblique.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedHeavyOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-HeavyItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-HeavyItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Quasi-Proportional Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: italic;
	src: url('/assets/fonts/Iosevka/WOFF2/IosevkaCustomQuasi-ExtendedHeavyItalic.woff2') format('woff2'), url('/assets/fonts/Iosevka/TTF/IosevkaCustomQuasi-ExtendedHeavyItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Terminal Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/IosevkaTerm/WOFF/IosevkaCustomTerm-Regular.woff2') format('woff2'), url('/assets/fonts/IosevkaTerm/TTF/IosevkaCustomTerm-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Custom Normal';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('/assets/fonts/IosevkaNormal/WOFF/IosevkaCustomNormal-Regular.woff2') format('woff2'), url('/assets/fonts/IosevkaNormal/TTF/IosevkaCustomNormal.ttf') format('truetype');
}